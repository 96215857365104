import * as THREE from 'three';

const pointLights = [
  {
    position: {
      x: 0, y: 0.49, z: 0.26
    },
    intensity: 10000,
  },
  {
    position: {
      x: 0, y: 0.49, z: -0.26
    },
    intensity: 10000,
  },

  {
    position: {
      x: 0.49, y: 0.49, z: -0.49
    },
    intensity: 12000
  },
  {
    position: {
      x: -0.49, y: 0.49, z: -0.49
    },
    intensity: 12000
  },
  {
    position: {
      x: 0.49, y: 0.49, z: 0.49
    },
    intensity: 12000
  },
  {
    position: {
      x: -0.49, y: 0.49, z: 0.49
    },
    intensity: 12000
  },

  {
    position: {
      x: 0.49, y: 0, z: -0.49
    },
    intensity: 10000
  },
  {
    position: {
      x: -0.49, y: 0, z: -0.49
    },
    intensity: 10000
  },
  {
    position: {
      x: 0.49, y: 0, z: 0.49
    },
    intensity: 10000
  },
  {
    position: {
      x: -0.49, y: 0, z: 0.49
    },
    intensity: 10000
  },

  {
    position: {
      x: 0, y: -0.49, z: 0
    },
    intensity: 2000
  },
]

export class RoomEnvironment extends THREE.Scene {
  constructor() {
    super();

    const boxGeometry = new THREE.BoxGeometry();
    boxGeometry.deleteAttribute('uv');

    const sphereGeometry = new THREE.SphereGeometry(0.5);
    sphereGeometry.deleteAttribute('uv');

    const roomMaterial = new THREE.MeshStandardMaterial({
      side: THREE.DoubleSide,
      roughness: 0.4,
      metalness: 0.2
    });
    roomMaterial.color.setScalar(1.8);

    const room = new THREE.Mesh(boxGeometry, roomMaterial);
    room.position.set(0, 0, 0);
    this.add(room);

    for (const lightParams of pointLights) {
      const pointLight = new THREE.PointLight(0xffffff, lightParams.intensity, 0, 2);
      pointLight.position.copy(lightParams.position);
      this.add(pointLight);
    }

    const blackHole = new THREE.Mesh(
      boxGeometry,
      new THREE.MeshBasicMaterial({
        color: 0x000000,
      })
    );
    blackHole.scale.set(0.45, 0.1, 0.45);
    blackHole.position.set(0, 0.49, 0);
    this.add(blackHole);

    this.rotateY(Math.PI);

    this.scale.multiplyScalar(240);
  }
}

export default RoomEnvironment;
